import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useAuthorisation() {
    const user = computed(() => usePage().props.auth.user);
    const permissions = computed(() => usePage().props.auth.permissions);
    const roles = computed(() => usePage().props.auth.roles);

    const userCan = (permission: string): boolean => {
        return permissions.value[`can_${permission}`] ?? false;
    }

    const userHasRole = (role: string): boolean => {
        return roles.value.includes(role);
    }

    return {
        user,
        userCan,
        userHasRole
    }
}
